import {
    GET_CONTENT_TAG_SHOW_IN_NAVIGATION
    } from './url.service'

import * as HttpService from "./http.service";



export const getContentTagShowInNavigation = (isContentTag='') => {
    console.log('calll')
    return HttpService.getWithAuth(GET_CONTENT_TAG_SHOW_IN_NAVIGATION(isContentTag))
}


