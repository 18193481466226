import Button from "@atoms/Button";
import Heading from "@atoms/Heading";
import Image from "next/image";
import React from "react";
import { useRouter } from "next/router";

function Card({ isColor, img, img1, selfHelp, title, btntype, btnText, link,clickFunc,isFunc=false }) {
  const router = useRouter();

  return (
    <div
      className={`sm:max-w-[370px] mx-auto md:mx-0 p-6  ${
        isColor ? "md:mb-0 mb-8" : ""
      } `}
    >
      <div className=" ">
        <div className="flex items-center gap-5 md:gap-4  mb-5 ">
          <Image src={img1} width={32} height={32} className="border-red-500" />
          <Heading
            type="h2"
            className="text-center leading-2 text-base md:text-2xl font-medium"
          > 
            {title}
          </Heading>
        </div>
        <ul className="list-none flex flex-col gap-3 md:gap-2 mb-4">
          {selfHelp.map((selfHelpDesc, index) => {
            return (
              <li
                key={index}
                className="flex items-center gap-2 text-sm md:text-base font-normal"
              >
                <div className="flex items-center flex-none">
                  <Image src={img} width={24} height={24} />
                </div>
                {selfHelpDesc}
              </li>
            );
          })}
        </ul>

        <Button
          variant={btntype ? "primary" : "primary"}
          className={`mt-8  !font-semibold`}
          onClick={isFunc ? ()=>clickFunc():() => router.push(link)}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
}

export default Card;
