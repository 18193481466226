
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/ypay",
      function () {
        return require("private-next-pages/ypay/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/ypay"])
      });
    }
  