import Container from "@atoms/Container";
import Image from "next/image";
import React, { useRef, useState, useEffect } from "react";
import Gallery from "./Gallary";
import Link from "next/link";
import { getContentTagShowInNavigation } from "@services/content-tag.service";
import { getYPayCouponDetails } from "@services/ypay.service";
import { getRefUserToken } from "@services/package.service";
import { useRouter } from "next/router";
import { setAuth } from "@services/identity.service";
import Head from "next/head";
import Card from "./Card";
import Button from "@atoms/Button";
import dayjs from 'utils/dayjs';
import { sendEventObj } from 'utils/constants'
import YPayLeadForm from "./YPayLeadForm";

function YPayLandingPage() {
  const [gallery, setGallery] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const staticGallery = [
    {
      id: "41d93ad0-ce76-4b06-98bc-e9c45fb86c0d",
      displayName: "Student and Education",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2023-03-06T05:19:12.911Z1678079952911Classroom.jpeg",
      contentCount: "22 content",
      slug: "students-and-education",
    },
    {
      id: "41d93ad0-ce76-4b06-98bc-e9c45fb86c0d",
      displayName: "Manage Emotions",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:26:10.291Z1664177170291manage%20emotions.png",
      contentCount: "55 content",
      slug: "manage-emotions",
    },
    {
      id: "41d93ad0-ce76-4b06-98bc-e9c45fb86c0d",
      displayName: "Feel Better",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:42:04.114Z1664178124114feel%20better.png",
      contentCount: "7 content",
      slug: "feel-better",
    },
    {
      id: "41d93ad0-ce76-4b06-98bc-e9c45fb86c0d",
      displayName: "Life and Relationships",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:41:54.338Z1664178114338life%20and%20relationship.png",
      contentCount: "7 content",
      slug: "life-and-relationship",
    },
  ];
  useEffect(() => {
    getContentTagShowInNavigation().then((res) => {
      if (res.status) {
        setGallery(res.entity.filter((item) => item.slug != "Journal"));
      }
    });
    getYPayCouponDetails().then((res) => {
      if (res.status) {
        setCouponDetails(res.entity);
      }
    });
    getRefUserToken(router.query.refId).then((res) => {
      if (res.status) {
        setAuth({ ...res.entity, refId: router.query.refId, loginYPayTime: dayjs().format() });
      }
    });
    sendEventObj({ 'landing_on_ypaypage_key': true }, 'user_lands_ypaypage')

  }, []);
  const router = useRouter();

  const onClickGalleryCont = (slug) => {
    window.open(`${process.env.NEXT_PUBLIC_MAIN_URL}/resources/${slug}`);
  };


  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }


  return (
    <>
      <div className=" bg-primary-100">
        <Container type="type0" className={`py-3 xl:py-4  flex justify-between items-center `}>
          <div className="">

            <div className="w-20 md:w-40">
              <Link href="https://www.lissun.app/">
                <a target="_blank">
                  <Image
                    src={`/assets/images/Ypay_white.svg`}
                    width={"120"}
                    height={"38"}
                    objectFit="contain"
                    objectPosition="left"
                  />
                </a>
              </Link>
            </div>
          </div>
          <div>


            <div className="flex w-full items-center gap-1 md:gap-4 ml-1.5 mt-1.5">

              <Link href="https://apps.apple.com/in/app/lissun/id1620115148">
                <a target='_blank' onClick={() => { }} >
                  <Image
                    src="/assets/images/appStore111.svg"
                    alt="App Store"
                    className=" object-contain"
                    width={135}
                    height={36}
                  />
                </a>
              </Link>
              <Link href="https://play.google.com/store/apps/details?id=com.lissun.prod">
                <a className="mr-1" target='_blank' onClick={() => { }} >
                  <Image
                    src="/assets/images/PlayStore111.svg"
                    alt="Google Play Store"
                    className=" object-contain"
                    width={135}
                    height={36}
                  />
                </a>
              </Link>
            </div>
          </div>
        </Container>


        <Head>
          <title>{"YPAY"}</title>
        </Head>

        <div className="HeroSection  pt-8 pb-8  md:pb-48 md:pt-36 bg-hero-ypay bg-center bg-cover  bg-blend-overlay bg-black bg-opacity-50 ">
          <Container type="type0">
            <div className="max-w-[760px]">
              <h2 className="font-Nunito font-normal text-2xl md:text-5xl text-white ">
                Tension ho rhi? Udaasi? Kuch ajeeb lag raha hai?
              </h2>

              <h1 className="text-white text-base md:text-2xl leading-6 md:leading-10 capitalize  max-w-[450px] mt-3 md:mt-5  font-normal">
                <span className="text-lightSpray ">LISSUN</span>
                <span className="text-white"> ke saath behtar mannsik aur bhavnatmak swasthya ki or kadam badhao </span>
              </h1>
              <Button
                variant="primary"
                className="max-w-[200px] my-5 w-full rounded-sm !font-semibold "
                width=""
                onClick={scrollToSection}

              >
                Know More
              </Button>
            </div>
          </Container>
        </div>

        <div className=" text-primary-80 py-7 px-2 bg-neutral-10 font-semibold text-xs md:text-[20px] text-center w-full mx-auto ">
          Need assistance from our support team? Contact us at
          +91-9821688144/+91-9821699488
        </div>

        <div className="SecondSection w-full bg-primary-100 py-8 md:py-10 ">
          <Container type="type0" className=" w-full mx-auto lg:flex lg:items-center lg:justify-between px-2 md:px-0">
            <div>

              <h3 className=" max-w-[450px] font-Nunito font-semibold text-xl md:text-3xl leading-8 text-center md:text-left mx-auto md:mx-0 text-white ">
                Sirf 49 mein pehla chat session book karo! Aur 99 mein video session!
              </h3>
              <Button
                variant="secondary"
                className="max-w-[300px] mx-auto md:mx-0 my-5 w-full rounded-[4.5px] text-[20px] !font-semibold font-Nunito !text-primary-100"
                width=""
                onClick={() => setShowContactDialog(true)}
              >
                I am Interested
              </Button>
            </div>

            <div>
              <img className="w-[417px] h-[320px] mt-0 mx-auto" src="/assets/images/groupYpay.svg" />
            </div>
          </Container>
        </div>
      </div>

      <Container type="type0" className="py-10">
        <div ref={sectionRef} className="max-w-[660px] text-center mx-auto">

          <h4 className="mt-3 md:mt-5  text-base md:text-[32px] leading-5 md:leading-8 font-semibold   text-center capitalize]">A  <span className="text-lightSpray text-base md:text-[32px] leading-5 md:leading-8 font-semibold  text-center ">Connected </span>  Mental Wellness Ecosystem</h4>
          <h2 className="font-Nunito font-normal text-sm md:text-[18px] mt-3  text-gray-400  ">
            Improve your mental health holistically, more consistently and experience change like never before.
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2  justify-center md:justify-between gap-7 mx-auto mt-8">
          <div>
            <img
              src="/assets/images/forming111.svg
                  "
              alt="selfhelp"
              className=" mt-12 mx-auto w-full md:w-fit "
            />
          </div>
          <div className="mt-6">
            <Card
              isColor={true}
              img={`/assets/images/BlackTick111.svg`}
              img1={`/images/self-help.svg`}
              selfHelp={[
                "Created By Mental Health Professionals",
                "Audio, Video, Blogs and Much More",
                "Access At Your Convenience",
                "Meditation and Mindfulness Exercises",
                "Questionnaires To Know Yourself Better",
              ]}
              title={"Free Self Help Tools"}
              btntype={true}
              btnText={"Explore Content"}
              link={"https://www.lissun.app/resources"}
            />
          </div>


          <div className="ml-0 md:ml-10">
            <Card
              isColor={true}
              img={`/assets/images/BlackTick111.svg`}
              img1={`/assets/images/xpert11.svg`}
              selfHelp={[
                "Qualified and Experienced Professionals",
                "High Quality Chat and Video Sessions",
                "Affordable Prices starting from Rs 49*",
                "Personalized Science Based Guidance",
                "Technology Assisted Support",
              ]}
              title={"Expert Guidance"}
              btntype={true}
              btnText={"Book a Session"}
              link={"https://www.lissun.app/connect-with-the-best-mental-health-counsellor-&-therapist-in-india"}
              isFunc={true}
              clickFunc={() => setShowContactDialog(true)}
            />
          </div>
          <div>
            <img
              src="/assets/images/FreeSelfHelp111.svg
              "
              alt="selfhelp"
              className=" -mt-8 -ml-6 mx-auto w-full md:w-fit"
            />
          </div>
        </div>
      </Container>

      <div className="bg-gray-100 py-4 md:py-8">
        <Container type="type0">
          <div className="flex justify-between items-center">
            <div className="w-fit">
              <h2 className=" text-xs md:text-[32px] font-medium leading-8 ">
                Check out resources for your specific concerns
              </h2>
              <h2 className="font-Nunito font-normal text-[10px] leading-4 md:text-[18px] mt-1.5 md:mt-3  text-gray-500  ">
                Explore our content library to help you on your growth
              </h2>
            </div>
            <div>
              <Button
                variant="secondary"
                className="  md:max-w-[514px] text-[10px] md:text-xl md:mx-auto !bg-transparent border-none p-1 w-full px-1 "
                width=""
                onClick={() => router.push("https://www.lissun.app/resources")}
              >
                Explore Content
                <span><img className="w-fit h-3 md:h-7 ml-1.5" src="/assets/images/ArrowRight111.svg" /></span>
              </Button>
            </div>
          </div>
          <Gallery gallery={staticGallery} onClick={onClickGalleryCont} isImageWithText={true} />

        </Container>
      </div >

      <div>
        <Container type="type0" className="bg-white py-9">
          <div className="  bg-white px-4 ">
            <div className="md:flex items-center justify-between ">
              <ul className=" list-disc flex flex-col gap-5 mb-12 md:max-w-[700px] max-w-[350px] ">
                <h2 className="md:text-3xl text-2xl font-semibold  leading-[20px] text-center">
                  Terms and Conditions
                </h2>
                <li className="text-base md:text-lg ">
                  Only one offer can be redeemed at a time and cannot be combined
                  with other offers
                </li>

                <li className="text-base md:text-lg">
                  Cost for availing the service shall be borne by the user directly
                </li>

                <li className="text-base md:text-lg">
                  YPay is not responsible in any manner for nature or quality of
                  products/services provided by LISSUN
                </li>

                <li className="text-base md:text-lg">
                  These offers are applicable one time per user per session. For
                  further sessions check pricing at LISSUN platform (discounted packages available)
                </li>
              </ul>

              <img className="w-[450] h-[450] mt-0 mx-auto" src="/assets/images/TermsGroup11.svg" />

            </div>
          </div>
        </Container>``
      </div>
      {showContactDialog && <YPayLeadForm onClose={() => setShowContactDialog(false)} />}
    </>

  );
}

export default YPayLandingPage;
