import Heading from "@atoms/Heading";

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupValidator } from "../../lib/yup-validator";
import * as yup from "yup";
import InputGroup from "@molecules/InputGroup";
import Button from "@atoms/Button";
import Image from "next/image";
import { createYPayLead } from "@services/ypay.service";
function YPayLeadForm({ onClose, className, placeholder }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                name: yup
                    .string()
                    .required("Name is mandatory")
                    .matches(/^[a-zA-Z ]*$/, "Please Enter Valid Name"),
                contact: yup
                    .string()
                    .required("Contact Number is mandatory")
                    .matches(/^[6789]\d{9}$/, "Invalid Phone Number"),
                message: yup
                    .string()
                    .required("Message is mandatory")
            })
        ),
    });


    const [display, setDisplay] = useState('form')
    const [showError, setShowError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const onSubmit = (data) => {
        setIsLoading(true)
        createYPayLead(data).then(response => {
            if (response.status) {
                setDisplay('thank')
                setIsLoading(false)
            } else {
                setShowError(response.message)
                setIsLoading(false)
            }
        })

    };


    return (
        <div
            className={`dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-40 z-[100] px-4 md:px-0 `}
        >
            <div
                className={`relative p-6 border-gray-400 border w-11/12 lg:max-w-[650px] bg-white mx-auto rounded-xl  `}
            >
                {display === 'form' &&
                    <Heading
                        type="h4"
                        className="w-full font-Nunito text-base md:text-2xl mb-1 font-medium  text-center mt-2"
                    >
                        Please fill in your details below
                    </Heading>
                }
                {
                    display === 'form' && (
                        <>
                            <div className="grid grid-cols-2 gap-5 mt-8 mb-6">
                                <InputGroup
                                    type="text"
                                    label="Name"
                                    register={register}
                                    errors={errors}
                                    inputClassName="bg-white   border-gray-400  outline-none rounded-lg border "
                                    name="name"
                                    placeholder={"Name"}
                                />
                                <InputGroup
                                    type="number"
                                    label="Phone Number"
                                    register={register}
                                    errors={errors}
                                    inputClassName="bg-white   border-gray-400  outline-none rounded-lg border"
                                    name="contact"
                                    placeholder={"Phone Number"}
                                />
                            </div>
                            <div className="">
                                <InputGroup
                                    type="textarea"
                                    label="What are you looking for support with? (Optional)"
                                    register={register}
                                    errors={errors}
                                    textareaClassName="bg-white   border-gray-400  outline-none rounded-lg border"
                                    name="message"
                                    placeholder={"Message"}
                                />

                                {
                                    showError && <div className="my-2 text-red-600 text-base font-semibold">{showError}</div>
                                }

                            </div>
                            <div className="w-full mt-6">
                                <Button
                                    disabled={isLoading}
                                    type="largeContained"
                                    onClick={handleSubmit(onSubmit)}
                                    className="px-24"
                                    width="w-full"
                                >
                                    Submit
                                </Button>
                            </div>
                        </>
                    )
                }
                {
                    display === 'thank' && <div className="flex flex-col items-center">
                        <div className="">
                            <Image
                                src={`/images/tick-circleGreen.svg`}
                                height={50}
                                width={50}
                                className="object-contain"
                            />
                        </div>
                        <div className="font-bold text-2xl my-2">
                            Successfully Submitted
                        </div>
                        <div className="text-center text-gray-500 px-8 w-[360px]">
                            You’re all set! Our team will be getting in touch with you soon.
                        </div>
                        <div className="flex justify-center w-[300px] mt-6">
                            <Button
                                disabled={isLoading}
                                type="largeContained"
                                onClick={() => onClose(false)}
                                className="px-24"
                                width="w-[300px]"
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                }
                <img
                    src="/images/Close.svg"
                    className=" cursor-pointer absolute top-4 right-3 "
                    onClick={() => onClose(false)}
                />
            </div>
        </div>
    );
}

export default YPayLeadForm;
