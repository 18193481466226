import {
    GET_Y_PAY_COUPON_DETAILS_URL,
    CREATE_Y_PAY_COUPON_URL,
    CREATE_YPAY_LEAD
} from './url.service';
import * as HttpService from "./http.service";

const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const getYPayCouponDetails = () => {
    return HttpService.getWithAuth(GET_Y_PAY_COUPON_DETAILS_URL());
};

export const createYPayCoupon = (details) => {
    return HttpService.putWithAuth(CREATE_Y_PAY_COUPON_URL, details);
};

export const createYPayLead = (details) => {
    return HttpService.postWithOutAuth(CREATE_YPAY_LEAD(), details);
};
